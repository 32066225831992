import { useCallback, useEffect, useState } from "react";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES"
import client, { patchData } from "../feathers"
import { getSortedList, getUniqueList, openBase64PdfInNewTab } from "../snippets"
import { ConfirmDialog } from "./ConfirmDialog";
import { v4 as uuidv4 } from 'uuid';
import { SelectButton } from 'primereact/selectbutton';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useCurrentUserHasRole, useCurrentUserIsMainMonitor } from "../Persistence/CurrentUserContext";
import { UnknownRFIDs } from "./UnknownRFIDs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { format, getHours, getMinutes, parseISO, setHours, setMinutes, setSeconds } from "date-fns";
import { Calendar } from "primereact/calendar";
import { ENUM_ICONS } from "./Icons";
import { ENUM_ROLES } from "../Enums/ENUM_ROLES";


const options = [{ name: 'Alarm', value: 1 }, { name: 'Übung', value: 2 }];
export const Department = ({ department, style, subject }) => {
    const currentUserIsMainMonitor = useCurrentUserIsMainMonitor();
    const currentUserHasRole = useCurrentUserHasRole();
    const [displayUnknownRFIDDialog, setDisplayUnknownRFIDDialog] = useState(false);
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
    const [displayConfirmPrintDialog, setDisplayConfirmPrintDialog] = useState(false);
    const [disablePrint, setDisablePrint] = useState(true);
    const [disableDelete, setDisableDelete] = useState(true);
    const [filterMode, setFilterMode] = useState(false);
    const [listtype, setListtype] = useState(1);
    const [dataTableList, setDataTableList] = useState([])
    const [selected, setSelected] = useState([])
    const [listUnknown, setListUnknown] = useState([])
    const [rfid, setRfid] = useState(null);
    const [alarmdate, setAlarmdate] = useState(null);
    const [alarmtime, setAlarmtime] = useState(null);
    const [resetdate, setResetdate] = useState(null);
    const [resettime, setResettime] = useState(null);


    useEffect(() => {
        const listUnknown = department.regdataUnknown.map(e => { return { id: e.rfidTagId } })
        setListUnknown(getUniqueList(listUnknown, 'id'))
    }, [department.regdataUnknown])


    useEffect(() => {
        const showTerminaldata = !currentUserHasRole(ENUM_ROLES.HAUPTWACHE)
        const regdataList = showTerminaldata ? department.regdataTerminal : department.regdata
        const list = regdataList.map(e => { return { id: e.personId, name: e.lastname + ', ' + e.firstname, time: format(e.time, 'HH:mm'), idRegdata: e.id } });
        const sortedList = getSortedList(list, filterMode ? 'time' : 'name')
        setDataTableList(filterMode ? sortedList : getUniqueList(sortedList, 'id'))
    }, [department.regdata, department.regdataTerminal, filterMode])


    useEffect(() => {
        const emptySubject = ((subject || '') === '')
        setDisablePrint(emptySubject || (filterMode && selected.length === 0))
        setDisableDelete(filterMode && selected.length === 0)
    }, [subject, selected, filterMode])


    const optionTemplate = (option) => {
        return <div>{option.name}</div>;
    }

    const clearRegdataList = async () => {
        const regdataList = filterMode ? selected : (currentUserIsMainMonitor ? department.regdata : department.regdataTerminal)
        const alarmTime = Math.min(regdataList.map(e => e.time)) || Date.now()
        await client.service(ENUM_SERVICES.REGDATALIST).create({ alarmdate, resetdate: Date.now(), department, regdata: regdataList, subject, listtype }).then(() => {
            client.service('utils').create({ method: 'updateMainMonitor' })
        })
    }

    const printRegdataList = async () => {
        const regdataList = filterMode ? selected : (currentUserIsMainMonitor ? department.regdata : department.regdataTerminal)
        setDisplayConfirmPrintDialog(false)
        const alarm = setSeconds(setMinutes(setHours(alarmtime, getHours(alarmtime)), getMinutes(alarmtime)), 0)
        const reset = setSeconds(setMinutes(setHours(resettime, getHours(resettime)), getMinutes(resettime)), 0)
        await client.service(ENUM_SERVICES.REGDATALIST).create({ alarmdate: alarm.getTime(), resetdate: reset.getTime(), department, regdata: regdataList, subject, listtype }).then(async (result) => {
            if (result) {
                await client.service('utils').create({ method: 'updateMainMonitor' })
                await openBase64PdfInNewTab(result.fileName, result.base64)
            }
        })
    }

    const toggleFilterMode = () => {
        setSelected([])
        setFilterMode(!filterMode)
    }

    const computeAlarmdate = () => {
        const regdataList = filterMode ? selected : (currentUserIsMainMonitor ? department.regdata : department.regdataTerminal)
        const timeList = regdataList.map(e => e.time)
        const minDate = Math.min(...timeList)
        setAlarmdate(minDate ? new Date(minDate) : Date.now())
        setAlarmtime(minDate ? new Date(minDate) : Date.now())
        setResetdate(new Date())
        setResettime(new Date())
    }

    const renderHeader = () => {
        return <div className='flex text-color px-3 p-2 w-full bg-white opacity-90 gap-3'>
            <div className='flex-grow-1'> {`${department.name} (${dataTableList.length})`}</div>
            <i className={`pt-1 justify-content-end pi pi-filter text-base text-color cursor-pointer`} onClick={toggleFilterMode}></i>
            <i className={`pt-1 justify-content-end pi pi-trash text-base ${disableDelete ? 'text-200' : 'text-color cursor-pointer'}`} onClick={() => { computeAlarmdate(); setDisplayConfirmDialog(true) }}></i>
            <i className={`pt-1 justify-content-end pi pi-print text-base ${disablePrint ? 'text-200' : 'text-color cursor-pointer'}`} onClick={() => { if (!disablePrint) { computeAlarmdate(); setDisplayConfirmPrintDialog(true) } }}></i>
        </div>
    }

    const renderDatatable = () => {
        return <div className='flex flex-column text-0 p-2 w-full overflow-auto flex-grow-1 department-datatable'>
            <DataTable
                emptyMessage=' '
                showHeaders={filterMode}
                value={dataTableList}
                selection={filterMode ? selected : null}
                onSelectionChange={(e) => setSelected(e.value)}
                dataKey="idRegdata" >
                <Column header='' hidden={!filterMode} selectionMode="multiple" style={{ width: '20px' }}></Column>
                <Column header='Zeit' hidden={!filterMode} field="time" style={{ width: '50px' }}></Column>
                <Column header='Name' field="name" ></Column>
            </DataTable>
        </div>
    }

    const renderUnknwn = () => {
        return listUnknown.length === 0 ? '' : <div className='flex flex-column text-0 p-2 w-full overflow-auto' style={{ maxHeight: '100px' }}>
            {listUnknown.map((e) => <div key={uuidv4()} className='flex w-full'>
                <div className='flex flex-grow-1'> {e.id}</div>
                <div onClick={() => {
                    setRfid(e.id);
                    setDisplayUnknownRFIDDialog(true)
                }}>
                    <i className="pi pi-user-edit" /></div>
            </div>)}
        </div>

    }

    return <>
        <UnknownRFIDs
            displayDialog={displayUnknownRFIDDialog}
            setDisplayDialog={setDisplayUnknownRFIDDialog}
            departmentId={department.id}
            departmentName={department.name}
            rfid={rfid} />
        <ConfirmDialog
            handleOnClick={() => clearRegdataList()}
            handleOnReject={() => setDisplayConfirmDialog(false)}
            labelCancel='Abbrechen'
            labelOk='Löschen'
            message={`<div class="flex flex-column text-lg gap-1">Liste der Abteilung<br/><span><b>${department.name}</b> mit  <b>${department.regdata.length}</b> Einträgen</span>löschen?</div>`}
            title='FFW-GD'
            displayConfirmDialog={displayConfirmDialog}
            setDisplayConfirmDialog={setDisplayConfirmDialog}
        />
        <Dialog
            header="FFW-GD"
            visible={displayConfirmPrintDialog}
            footer={
                <div>
                    <Button label="Abbrechen" icon="pi pi-times" onClick={() => setDisplayConfirmPrintDialog(false)} autoFocus className='button-cancel' />
                    <Button label="Drucken" icon="pi pi-print" onClick={() => printRegdataList()} className="p-button-text" />
                </div>
            }
            onHide={() => setDisplayConfirmPrintDialog(false)}>
            <div className="flex flex-column gap-1 text-lg">
                <div>Liste der Abteilung <b>{department.name}</b> </div>
                <div>mit {department.regdata.length === 1 ? <span><b>einem</b> Eintrag</span> : <span><b>{department.regdata.length}</b> Einträgen</span>} als</div>
                <SelectButton
                    id="dialog-print-selectbutton"
                    className="dialog-print-selectbutton my-2"
                    value={listtype}
                    options={options}
                    optionLabel='name'
                    optionValue='value'
                    itemTemplate={optionTemplate}
                    onChange={(e) => setListtype(e.value)}
                />
                <div className='flex gap-2 mt-3'>
                    <label className='w-6rem'>Alarm Start:</label>
                    <Calendar
                        className='w-8rem'
                        value={alarmdate}
                        icon={ENUM_ICONS.CALENDAR}
                        showIcon
                        dateFormat="dd.mm.yy"
                        onChange={(e) => setAlarmdate(e.value)}
                    />
                    <Calendar
                        className='w-6rem'
                        value={alarmtime}
                        timeOnly
                        mask={'99:99'}
                        icon={ENUM_ICONS.CLOCK}
                        showIcon
                        dateFormat="dd.mm.yy"
                        onChange={(e) => setAlarmtime(e.value)}
                    />
                </div>
                <div className='flex gap-2 mb-3'>
                    <label className='w-6rem'>Alarm Ende:</label>
                    <Calendar
                        className='w-8rem'
                        value={resetdate}
                        icon={ENUM_ICONS.CALENDAR}
                        showIcon
                        dateFormat="dd.mm.yy"
                        onChange={(e) => setResetdate(e.value)}
                    />
                    <Calendar
                        className='w-6rem'
                        value={resettime}
                        timeOnly
                        mask={'99:99'}
                        icon={ENUM_ICONS.CLOCK}
                        showIcon
                        dateFormat="dd.mm.yy"
                        onChange={(e) => setResettime(e.value)}
                    />
                </div>
                <div>drucken und schließen?</div>
            </div>
        </Dialog>
        <div key={'department-' + department.id} className={'flex flex-column department-card '} style={style}>
            {renderHeader()}
            {renderDatatable()}
            {renderUnknwn()}
        </div>
    </>
}