import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { usePersonList } from "../Persistence/PersonContext";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { deleteData, findData, patchData } from "../feathers";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ConfirmDialog } from "./ConfirmDialog";



export const UnknownRFIDs = ({ displayDialog, setDisplayDialog, departmentId, departmentName, rfid }) => {
    const currentUser = useCurrentUser();
    const personList = usePersonList();
    const [dataTableList, setDataTableList] = useState([]);
    const [filters, setFilters] = useState({
        'lastname': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'id': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'firstname': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [mounted, setMounted] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selected, setSelected] = useState(null);
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

    useEffect(() => {
        if (personList) {
            setDataTableList(personList.filter((e) => e.departmentId === departmentId))
        }
    }, [departmentId, personList])

    const onSelect = (e) => {
        console.log(e)
    }

    const onValueChanged = (data) => {
        setTotalRecords(data ? data.length : 0)
    }

    const assignRFID = async () => {
        await patchData(ENUM_SERVICES.RFIDTAGS, { rfid, personId: selected.id, updatedBy: currentUser.id }).then(() => {
            setDisplayDialog(false)
        })
    }

    const deleteRFID = async () => {
        setDisplayDialog(false)
        await findData(ENUM_SERVICES.REGDATA, { rfidTagId: rfid }).then(async (result) => {
            for (let regdata of result) {
                await deleteData(ENUM_SERVICES.REGDATA, regdata.id)
            }
        })
    }

    const renderFooter = () => {
        return <div>Anzahl der angezeigten Personen: {totalRecords}</div>
    }
    const onRowSelect = (event) => {
        // Toggle selection
        if (selected?.id === event.data.id) {
            setSelected(null); // Deselect if already selected
        } else {
            setSelected(event.data); // Select new row
        }
    };

    return <div key={'UnknownRFIDs'} className={'flex flex-column department-card '} >
        <ConfirmDialog
            handleOnClick={deleteRFID}
            handleOnReject={() => setDisplayConfirmDialog(false)}
            labelCancel='Abbrechen'
            labelOk='Löschen'
            message={`<div class="flex flex-column text-lg gap-1">Unbekannter RFID Tag<br/><span><b>${rfid}</b></span>löschen?</div>`}
            title='FFW-GD'
            displayConfirmDialog={displayConfirmDialog}
            setDisplayConfirmDialog={setDisplayConfirmDialog}
        />
        <Dialog
            className="flex"
            style={{ height: '720px' }}
            header={<div className='text-xl '>FFW-GD - RFID {rfid} einer Person zuweisen</div>}
            visible={displayDialog}
            footer={
                <div className='flex'>
                    <div className='flex flex-grow-1'>
                        <Button label="Eintrag löschen" icon="pi pi-trash" onClick={() => setDisplayConfirmDialog(true)} className='bg-red-700' />
                    </div>
                    <Button label="Abbrechen" icon="pi pi-times" onClick={() => setDisplayDialog(false)} autoFocus className='button-cancel' />
                    <Button label={`${selected ? selected.lastname + ', ' + selected.firstname : ''}   zuweisen`} icon="pi pi-check" onClick={() => assignRFID()} disabled={!selected} />
                </div>
            }
            onHide={() => setDisplayDialog(false)}>
            <div className="flex flex-column gap-1 text-lg" >
                <div className='flex text-xl my-1' >Abteilung: {departmentName}</div>
                <DataTable
                    value={dataTableList}
                    onValueChange={onValueChanged}
                    emptyMessage={'keine Einträge gefunden'}
                    scrollHeight={'500px'}
                    selectionMode="single"
                    selection={selected}
                    onRowClick={onRowSelect}
                    scrollable
                    removableSort
                    sortField="lastname"
                    sortOrder={1}
                    filterDisplay='row'
                    filters={filters}
                    responsiveLayout="scroll"
                    dataKey="id"
                    stripedRows
                    rowHover
                    onSelect={onSelect}
                    footer={renderFooter}
                >
                    <Column header='Id' field='id' style={{ flexGrow: 0, flexBasis: '80px' }} sortable showFilterMenu={false} />
                    <Column header='Name' field='lastname' filter style={{ flexGrow: 1, flexBasis: '200px' }} sortable showFilterMenu={false} />
                    <Column header='Vorname' field='firstname' filter style={{ flexGrow: 1, flexBasis: '200px' }} sortable showFilterMenu={false} />
                </DataTable>
            </div>
        </Dialog>
    </div>
}