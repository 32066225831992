import { useRef, useState } from 'react';
import client from '../feathers';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password'
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { updateLastAction } from '../Persistence/LoginContext';
import { LoggerLogin } from '../logger';
import * as Sentry from "@sentry/react";
import { useLocation } from 'react-router-dom';

const INACTIVE_INTERVALL = 14 * 60 * 60;        // in seconds

export const Login = () => {

	const toast = useRef(null);
	const location = useLocation();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [userInactiveIntervall, setUserInactiveIntervall] = useState(INACTIVE_INTERVALL / 60);
	const [keepAlive, setKeepAlive] = useState(true);
	const [error, setError] = useState('');

	const loginUser = () => {
		setError('')
		//LoggerLogin.debug('userInactiveIntervall: ' + userInactiveIntervall);
		LoggerLogin.info('userInactiveIntervall:' + userInactiveIntervall)
		reactLocalStorage.set('keepAlive', keepAlive);
		reactLocalStorage.set('userInactiveIntervall', userInactiveIntervall);
		updateLastAction();
		var loginParams = keepAlive ? { strategy: 'local', username, password } : { strategy: 'local', username, password, expiresIn: userInactiveIntervall + 'M' }
		return client.authenticate(loginParams).then((login) => {
			Sentry.addBreadcrumb({
				category: "auth",
				message: "Login user " + login.user.email,
				level: "info",
			});
		}).catch(error => {
			LoggerLogin.error("ERROR login fehlgeschlagen: " + error.message);
			loginParams = keepAlive ? { strategy: 'email', email: username, password } : { strategy: 'email', email: username, password, expiresIn: userInactiveIntervall + 'M' }
			return client.authenticate(loginParams).catch(error => {
				showToast(error.message);
			});
		});
	}

	useEffect(() => {
		setUserInactiveIntervall(parseInt(reactLocalStorage.get('userInactiveIntervall', '' + INACTIVE_INTERVALL)) / 60);
		setKeepAlive(reactLocalStorage.get('keepAlive', 'false') === 'true');
	}, [])

	const showToast = (message) => {
		toast.current.show({ severity: 'warn', summary: 'Login fehlgeschlagen', detail: message, sticky: true });
	}

	return <div id="login-container" className='flex h-full'>
		<Toast ref={toast} />
		<div className="flex justify-content-center mx-auto my-auto shadow-2 border-round-md" style={{ height: '600px', width: '1080px' }} >
			<div className='flex justify-content-center w-6 h-full'>
				<img src={`./icons/FFW-GD-Wappen.png`} className='flex align-items-center my-auto m-4 w-8' />
			</div>
			<div className={'flex justify-content-center text-white border-round-right-md w-6 h-full department-card '}>
				<div className='mx-auto my-auto w-20rem'>
					<div className="hidden formgrid grid" >
						<p style={{ color: 'red' }} >{error}</p>
					</div>
					<div className="field flex flex-column mb-4 text-xl font-semibold" >
						Anmeldung
					</div>
					<div className="field flex flex-column" >
						<label htmlFor="mail" className=' text-lg'>Anmeldenamen</label>
						<InputText autoFocus={true} autoComplete="nope" placeholder="Anmeldenamen" value={username}
							onChange={(e) => { setUsername(e.target.value ? e.target.value.trim() : '') }}
							id="mail" className={'' + (username === '' ? 'p-invalid' : '')}
							onKeyUp={e => { e.key === 'Enter' && password && username ? loginUser() : setUsername(e.target.value) }} />
					</div>
					<div className="field flex flex-column" >
						<label htmlFor="password" className=' text-lg'>Passwort</label>
						<Password autoComplete="nope"
							toggleMask
							feedback={false}
							placeholder="Passwort"
							value={password}
							onChange={(e) => { setPassword(e.target.value ? e.target.value.trim() : '') }}
							id="password"
							className={'w-full ' + (password === '' ? 'p-invalid' : '')}
							inputClassName='flex-grow-1'
							onKeyUp={e => { e.key === 'Enter' && password && username ? loginUser() : setPassword(e.target.value) }} />
					</div>
					<div className="formgrid grid p-2">
						<Checkbox inputId="binary" checked={keepAlive} onChange={e => setKeepAlive(e.checked)} value={keepAlive} />
						<label htmlFor="binary" className='ml-2 text-lg'>Angemeldet bleiben</label>
					</div>
					<div className={!keepAlive ? "hidden" : "hidden"}>
						<label className='flex my-auto'>Logout nach </label>
						<InputNumber autoComplete="nope" className={!keepAlive ? "col sm:inputtext" : "hidden"} id="userInactiveIntervall" value={userInactiveIntervall} onValueChange={(e) => setUserInactiveIntervall(e.value)} showButtons min={5} buttonLayout="horizontal" step={5} suffix=' min' />
						<label className='flex my-auto'>Inaktivität</label>
					</div>
					<div className="flex justify-content-end">
						<div className="field ">
							<Button className={'p-button-success p-2 my-4'} icon="fa fa-sign-in text-xl" iconPos="right" onClick={() => loginUser()} label="Anmelden" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div >
}