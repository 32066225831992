import React, { useContext, useState, useEffect, useCallback } from "react";
import client, { getData } from '../feathers'
import { logContext } from "../logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getSortedList } from "../snippets";

const TerminalContext = React.createContext();

const servicename = ENUM_SERVICES.LASTALIFE;
const listenernames = ['created', 'patched'];
const providername = 'TerminalProvider';

export function useTerminalList() {
    return useContext(TerminalContext);
}

export function TerminalProvider({ children }) {
    const [terminalList, setTerminalList] = useState([]);
    const [mounted, setMounted] = useState(false)

    const updateLastAlifeTerminal = useCallback((lastAlifeTerminal) => {
        if (Array.isArray(lastAlifeTerminal)) {
            logContext(providername, 'info', `###### ${providername}:${lastAlifeTerminal?.map(e => `${e.name} (${e.serialnumber} -> ${e.overdueTime})`).join('\n')}`);
            setTerminalList(getSortedList(lastAlifeTerminal, 'name'))
        }
    }, [terminalList])


    useEffect(() => {
        setMounted(true)
        const serviceLastAlife = client.service(servicename);
        listenernames.forEach((listener) => {
            serviceLastAlife.removeListener(listener);
            serviceLastAlife.on(listener, updateLastAlifeTerminal);
        })
    }, [terminalList])

    useEffect(() => {
        if (mounted) {
            getData(servicename).then((result) => {
                setTerminalList(getSortedList(result, 'name'))
            })
            return () => { logContext(providername, 'debug', `########### ${providername} ---- unload`); removeListener(); }
        }
    }, [mounted])

    const removeListener = () => {
        const service = client.service(servicename);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
        })
    }

    return (
        <TerminalContext.Provider value={terminalList}>
            {children}
        </TerminalContext.Provider>
    )
}